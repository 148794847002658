import React from "react";
import ImportService from "../services/ImportService";

class ImportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file:null
    };
  }

  onChange = (e) => {
    this.setState({file:e.target.files[0]})
  }

  uploadFile = () => {
    ImportService.defaultImport(this.state.file)
      .then(response => {
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    return (
      <div>
        <form>
          <div className="mb-3">
            <label className="form-label">Excel файл для импорта</label>
            <input
              className="form-control"
              type="file"
              onChange={this.onChange}
            />
          </div>
        </form>
        <div className="row gy-3 mt-5 mb-5 justify-content-end">
            <div className="col-6 col-md-3 d-grid">
              <button type="submit" className="btn btn-primary" onClick={this.uploadFile}>Импортировать из excel файла</button>
            </div>
          </div>
      </div>
    );
  }
}

export default ImportForm;