import React from "react";
import AdminService from "../services/AdminService";

class AdminForm extends React.Component {
  constructor(props) {
    super(props);
  }

  deleteAll = () => {
    AdminService.deleteAll();      
  }

  render() {
    return (
      <div className="row gy-3 mt-5 mb-5 justify-content-end">
        <div className="col-6 col-md-3 d-grid">
          <button className="btn btn-danger" onClick={this.deleteAll}>Удалить весь каталог</button>
        </div>
      </div>
    );
  }
}

export default AdminForm;