import http from "../../http-common";

const defaultImport = file => {
  const formData = new FormData();
  formData.append('excelFile', file)
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }
  return  http.post("/import", formData,config)
};

export default {
  defaultImport,
};