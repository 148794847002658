import React from "react";
import ExportService from "../services/ExportService";

class ExportForm extends React.Component {
  constructor(props) {
    super(props);
  }

  downloadFile = () => {
    ExportService.defaultExport();      
  }

  render() {
    return (
      <div className="row gy-3 mt-5 mb-5 justify-content-end">
        <div className="col-6 col-md-3 d-grid">
          <button className="btn btn-primary" onClick={this.downloadFile}>Экспортировать каталог в excel файла</button>
        </div>
      </div>
    );
  }
}

export default ExportForm;