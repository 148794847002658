import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";

import CategoryRoute from "./components/category/CategoryRoute";
import ImportForm from "./components/import/ImportForm";
import ExportForm from "./components/export/ExportForm";
import AdminForm from "./components/admin/AdminForm";

export default function App() {

  useEffect(() => {
    document.title = "Estimator - автоматический сметчик"
  }, []);

  return (
    <>
      <Router>
        <nav className="navbar navbar-expand-lg navbar navbar-dark bg-primary">
          <div className="container">
            <a className="navbar-brand" href="#">Estimator (автосметчик)</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <ActiveLink
                    activeOnlyWhenExact={false}
                    to="/category"
                    label="Каталог"
                  />
                </li>
                <li className="nav-item">
                <ActiveLink
                    activeOnlyWhenExact={false}
                    to="/import"
                    label="Импорт из Excel"
                  />
                </li>
                <li className="nav-item">
                <ActiveLink
                    activeOnlyWhenExact={false}
                    to="/export"
                    label="Экспорт в Excel"
                  />
                </li>
                <li className="nav-item">
                <ActiveLink
                    activeOnlyWhenExact={false}
                    to="/admin"
                    label="Администрирование"
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container mt-3">
              <Switch>
                <Route path="/category">
                  <CategoryRoute />
                </Route>
                <Route path="/import">
                  <ImportForm />
                </Route>
                <Route path="/export">
                  <ExportForm />
                </Route>
                <Route path="/admin">
                  <AdminForm />
                </Route>
                <Redirect from="*" to="/category" />
              </Switch>
        </div>
      </Router>
    </>
  );
}

function ActiveLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
    <Link className={match ? "nav-link active" : "nav-link"} to={to}>{label}</Link>
  );
}