import React from "react";
import { withRouter } from 'react-router-dom';
import CategoryService from "../services/CategoryService";
import Loader from "../loader/Loader";
import CategoryProductList from "./CategoryProductList";
import { Link } from 'react-router-dom';

class CategoryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      model: {
        id: null,
        name: ""
      }
    };
  }

  componentDidMount() {
    this.getModel();
  }

  getModel = () => {
    CategoryService.get(this.props.match.params.categoryId)
      .then(response => {
        this.setState({ model: response.data, loader: false });
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const { name } = this.state.model;
    return (
      this.state.loader ?
        <Loader />
        :
        <>
          <div className="row">
            <div className="col-6">
              <span className="fs-4">{name}</span>
            </div>
            <div className="col-6 text-end">
              {<Link
                to={`/category/edit/${this.props.match.params.categoryId}`}
                className="btn btn-outline-primary btn-sm mr-1">
                Редактировать
              </Link>}&nbsp;
              {<Link to={`/category/${this.props.match.params.categoryId}`} className="btn btn-outline-danger btn-sm mr-1">                
                Удалить <i className="bi bi-x-circle"></i>
              </Link>}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {
                <CategoryProductList categoryId={this.props.match.params.categoryId} />
              }
            </div>
          </div>
        </>
    );
  }
}

export default withRouter(CategoryView);