import http from "../../http-common";

const getAllInCategory = categoryId => {
  return http.get(`/category/${categoryId}/products`);
};

const get = id => {
  return http.get(`/product/${id}`);
};

const store = (categoryId, data) => {
  console.log(data);
  return http.post(`/category/${categoryId}/product`, data);
};

const update = (id, data) => {
  return http.put(`/product/${id}`, data);
};

const destroy = id => {
  return http.delete(`/product/${id}`);
};

export default {
  getAllInCategory,
  get,
  store,
  update,
  destroy,
};