import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ProductService from "../services/ProductService";
import Loader from "../loader/Loader";

class CategoryProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      models: []
    };
  }

  componentDidMount() {
    this.getModels()
  }

  getModels = () => {
    ProductService.getAllInCategory(this.props.categoryId)
      .then(response => {
        console.log(response.data);
        this.setState({ models: response.data, loader: false });
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    return (
      this.state.loader ?
        <Loader />
        :
        <div style={{ fontSize: 11 }}>
          <table className="table table-hover table-bordered table-sm">
            <thead>
              <tr>
                <th style={{ width: "40px", textAlign: "center"}}>#</th>
                <th style={{ width: "60%" }}>Наименование</th>
                <th>Тип, марка, артикул</th>
                <th>Характеристика</th>
                <th>Изготовитель</th>
                <th style={{ width: "40px", textAlign: "center"}}>Ед. изм.</th>
                <th style={{ width: "50px", textAlign: "center"}}>Цена за ед.</th>
              </tr>
            </thead>
            <tbody>
              {this.state.models.map((product, key) => {
                const { id, name, model, parameter, manufacturer, unit, price } = product;
                return (
                  <tr key={key}>
                    <td style={{ textAlign: "center" }}>{id}</td>
                    <td>{name}</td>
                    <td>{model}</td>
                    <td>{parameter}</td>
                    <td>{manufacturer}</td>
                    <td style={{ textAlign: "center"}}>{unit}</td>
                    <td style={{ textAlign: "center"}}>{price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
    );
  }
}

export default withRouter(CategoryProductList);
