import React, { Component } from "react";
import CategoryService from "../services/CategoryService";
import Category from "./Category";

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      models: []
    };
  }

  componentDidMount() {
    this.getModels()
  }

  getModels = () => {
    CategoryService.getAll()
      .then(response => {
        console.log(response.data);
        this.setState({ models: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    return (
      <>
        {this.state.models.map((category, key) => {
          return (
            <Category key={key} category={category} level={0}/>
          )
        })}
      </>
    );
  }
}

export default CategoryList;
