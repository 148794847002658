import http from "../../http-common";

const defaultExport = () => {

  const config = {
    responseType: 'blob'
  }
  http.get("/export", config).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'products.xlsx'); 
    document.body.appendChild(link);
    link.click();
    console.log(response.data);
  })
  .catch(e => {
    console.log(e);
  });
};

export default {
  defaultExport,
};