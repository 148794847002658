import React from "react";
import { Link } from 'react-router-dom';
import CategoryProductList from "./CategoryProductList";

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false
    };
  }

  expandCategory = () => {
    if(this.props.category.productsCount > 0)
    {
      if(this.state.expand)
      {
        this.setState({ expand: false });
      }else
      {
        this.setState({ expand: true });
      }
    }
  }

  render() {
    const level = this.props.level;
    const { id, name, categories, productsCount } = this.props.category;
    return (
      <>
        <div className="row">
          <div className={`col-${level}`}>
          </div>
          <div className={`col-${10 - level}`}>
            {level > 0 ? <i className="bi bi-arrow-return-right"></i> : <></>} <a href="#" onClick={this.expandCategory}>{name}</a>&nbsp;
            {
              productsCount > 0 ?
                this.state.expand ?
                <i className="bi bi-arrow-up-square"></i>:<i className="bi bi-arrow-down-square"></i>
                :
                <></>
            }
          </div>
          <div className="col-2 text-end">
            {<Link to={`/category/${id}`} className=""><i className="bi bi-pencil-square"></i></Link>}
          </div>
        </div>
        {
          categories.length > 0 ?
            <>
              {categories.map((category, key) => {
                return (
                  <Category key={key} category={category} level={level + 1} />
                )
              })}
            </>
            :
            <>
            </>
        }
        {
          this.state.expand ?
            <>
              <div className="row">
                <div className={`col-${level}`}>
                </div>
                <div className={`col-${12 - level}`}>
                  <CategoryProductList categoryId={this.props.category.id} />
                </div>
              </div>
            </>
            :
            <>
            </>
        }
      </>
    );
  }
}

export default Category;