import React, { Component } from "react";
import CategoryList from "./CategoryList";
import CategoryView from "./CategoryView";
import { Route, Switch } from 'react-router-dom';

class CategoryRoute extends Component {
  
  render() {
    return (
      <Switch>
        <Route exact path="/category">
          <CategoryList />
        </Route>
        <Route path="/category/:categoryId">
          <CategoryView />
        </Route>
      </Switch>
    );
  }
}

export default CategoryRoute;