import React from "react";

class Loader extends React.Component {

    render() {
        return (
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        )
    }

}

export default Loader;